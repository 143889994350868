import { SectionCard } from '../../components/cards/SectionCard';
import { Grid } from '@mui/material';
import TextFieldAdapter from '../../form/components/TextFieldAdapter';
import { FormGridContainer } from '../../components/grid/FormGridContainer';

export const DemandGeneralCommentSection = () => {
  return (
    <SectionCard title="Commentaire général">
      <FormGridContainer>
        <Grid item xs={12}>
          <TextFieldAdapter multiline rows={5} name="comment" label="" />
        </Grid>
      </FormGridContainer>
    </SectionCard>
  );
};
