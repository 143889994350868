import React, { forwardRef } from 'react';
import { SnackbarContent, SnackbarKey, SnackbarMessage } from 'notistack';
import { Alert } from '@mui/material';
import { getMuiSeverity, Severity } from '../utils/severityUtils';

type Props = {
  id: SnackbarKey;
  message: SnackbarMessage;
  severity: Severity;
};

export const Snackbar = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { id, message, severity } = props;
  return (
    <SnackbarContent key={id} data-testid={`${severity}-alert-snackbar`} ref={ref}>
      <Alert
        severity={getMuiSeverity(severity)}
        elevation={6}
        variant="filled"
        sx={{
          color: 'white',
          width: '100%',
        }}
      >
        {message}
      </Alert>
    </SnackbarContent>
  );
});
