import React, { ReactNode } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useField } from 'react-final-form';
import ReadOnlyField from './ReadOnlyField';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import { isDisabledOrReadFormMode } from '../helper/formUtils';
import { Dayjs } from 'dayjs';
import { useFormMode } from '../helper/FormModeProvider';
import { EnhancedTextField } from './EnhancedTextField';
import {
  composeValidators,
  FieldValidator,
  mandatory,
  maxDate as maxDateValidator,
  minDate as minDateValidator,
  validDate,
} from '../helper/validators';
import { TextFieldProps } from '@mui/material';
import { FormFieldLabel } from './FormFieldLabel';
import { SlotComponentProps } from '@mui/base/utils';
import TextField from '@mui/material/TextField';

export type DatePickerAdapterProps = {
  disabled?: boolean;
  label?: string;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  name: string;
  required?: boolean;
  warningText?: string;
  validators?: FieldValidator<Dayjs | undefined>[];
  infoTooltipContent?: ReactNode;
};

export const DatePickerAdapter = ({
  disabled = false,
  label,
  maxDate,
  minDate,
  name,
  required = false,
  warningText,
  validators = [],
  infoTooltipContent,
}: DatePickerAdapterProps) => {
  const { input, meta } = useField(name, {
    validate: composeValidators([
      required && mandatory,
      ...validators,
      validDate,
      minDate && minDateValidator(minDate),
      maxDate && maxDateValidator(maxDate),
    ]),
  });
  const formMode = useFormMode();
  const dataTestId = `${name}-field`;

  if (isDisabledOrReadFormMode(disabled, formMode)) {
    return (
      <ReadOnlyField
        label={label}
        value={input.value}
        required={required}
        infoTooltipContent={infoTooltipContent}
        dataTestId={dataTestId + '-readonly'}
      />
    );
  }

  const selectDate = (date: Dayjs | null) => {
    input.onChange(date ? date.startOf('day') : date);
  };

  const touchField = () => {
    input.onBlur();
  };

  const displayError = Boolean((meta.error || meta.submitError) && meta.touched);

  let actions: PickersActionBarAction[];
  if (required) {
    actions = ['cancel', 'accept'];
  } else {
    actions = ['clear', 'cancel', 'accept'];
  }

  const textFieldProps: SlotComponentProps<typeof TextField, {}, Record<string, any>> = (props) => ({
    ...props,
    error: displayError,
    helperText: (displayError && (meta.error || meta.submitError)) || warningText,
    onBlur: touchField,
    warning: Boolean(warningText),
    dataTestId,
  });

  return (
    <DesktopDatePicker
      closeOnSelect
      label={label && <FormFieldLabel label={label} required={required} infoTooltipContent={infoTooltipContent} />}
      maxDate={maxDate}
      minDate={minDate}
      onChange={selectDate}
      onClose={touchField}
      value={input.value || null}
      slotProps={{
        actionBar: { actions },
        textField: textFieldProps,
      }}
      slots={{
        textField: DatePickerTextField,
      }}
    />
  );
};

type DatePickerTextFieldProps = TextFieldProps & {
  dataTestId: string;
  displayError: boolean;
  error: boolean;
  helperText: string | null;
  onBlur: () => void;
  warning?: boolean;
};

function DatePickerTextField({ dataTestId, error, helperText, onBlur, warning, ...others }: DatePickerTextFieldProps) {
  return (
    <EnhancedTextField
      {...others}
      data-testid={dataTestId}
      variant="standard"
      fullWidth
      InputLabelProps={{ shrink: true }}
      error={error}
      helperText={helperText}
      onBlur={onBlur}
      InputProps={{
        ...others.InputProps,
        placeholder: 'JJ/MM/AAAA',
      }}
      warning={Boolean(warning)}
    />
  );
}
