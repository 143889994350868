import { Demand } from '../../../demandTypes';
import MainActionButton from '../../../../components/buttons/MainActionButton';
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import SecondaryActionButton from '../../../../components/buttons/SecondaryActionButton';
import { Form } from 'react-final-form';
import TextFieldAdapter from '../../../../form/components/TextFieldAdapter';
import { FormMode, FormModeProvider } from '../../../../form/helper/FormModeProvider';
import { maxLength } from '../../../../form/helper/validators';
import useSnackbar from '../../../../snackbar/useSnackbar';
import ApiHelper from '../../../../api/apiHelper';
import { Severity } from '../../../../utils/severityUtils';
import { captureException } from '@sentry/react';

type AddCommentButtonProps = {
  demand: Demand;
  refreshDemand: () => void;
};

const LENGTH_VALIDATOR = maxLength(500);

function AddCommentButton({ demand, refreshDemand }: AddCommentButtonProps) {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [pending, setPending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const openDialog = () => {
    setDialogOpened(true);
  };

  const closeDialog = () => {
    setDialogOpened(false);
  };

  const onSubmit = async ({ text }: { text: string }) => {
    try {
      setPending(true);
      await ApiHelper.postJson(`/api/demands/${demand.id}/comments`, { text });
      setDialogOpened(false);
      enqueueSnackbar({
        content: 'Le commentaire a été ajouté avec succès',
        severity: Severity.SUCCESS,
      });
      refreshDemand();
    } catch (e) {
      captureException(e);
      enqueueSnackbar({
        content: "Une erreur est survenue pendant l'ajout du commentaire",
        severity: Severity.ERROR,
      });
    } finally {
      setPending(false);
    }
  };

  return (
    <>
      <MainActionButton dataTestId="add-comment-button" action={openDialog} label="Ajouter un commentaire" />
      <Dialog open={dialogOpened} onClose={closeDialog} fullWidth>
        <FormModeProvider mode={FormMode.EDIT}>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <DialogTitle>Ajouter un commentaire</DialogTitle>
                <DialogContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextFieldAdapter
                        label="Commentaire"
                        multiline
                        name="text"
                        required
                        validators={[LENGTH_VALIDATOR]}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <SecondaryActionButton label="Annuler" action={closeDialog} disabled={pending} />
                  <MainActionButton
                    label="Ajouter le commentaire"
                    type="submit"
                    disabled={pending}
                    pendingSpinner={pending}
                  />
                </DialogActions>
              </form>
            )}
          </Form>
        </FormModeProvider>
      </Dialog>
    </>
  );
}

export default AddCommentButton;
