import { OptionsObject, useSnackbar as useNotistackSnackbar } from 'notistack';
import { SnackbarRequest } from './snackbarUtils';
import { useCallback } from 'react';

const useSnackbar = () => {
  const { enqueueSnackbar: notistackEnqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  /**
   * This function is safe to be passed as dependency to useEffect/useMemo/etc...
   */
  const enqueueSnackbar = useCallback(
    ({ content, severity }: SnackbarRequest) => notistackEnqueueSnackbar(content, { severity } as OptionsObject),
    [notistackEnqueueSnackbar],
  );

  return { enqueueSnackbar, closeSnackbar };
};

export default useSnackbar;
